.button-vertical {
    width: 50px;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    padding: 15px !important;
    min-width: 10px !important;
}

.button-tap-fixed {
    position: fixed !important;
    right: 0px;
}

.button-tap {
    left: -50px;
    position: absolute !important;
}

.vertical-text{
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-transform: none !important;
}

.button-floating {
    position: absolute;
    height: 600px;
    display: flex;
    flex-direction: column;
    border-radius: 8px 0px 0px 0px;
    bottom: 0px;
    right: 0;
}

.modal-cover-tab {
    border-radius: 0px !important;
}
.modal-cover {
    position: absolute;
    width: 600px;
    height: 600px;
    border-radius: 8px 0px 0px 0px;
    bottom: 0px;
    right: 0;
    background-color: #FFFFFF;
    max-height: 600px;
    max-width: 600px;
    box-shadow: 0px 0px 8px -3.5px rgba(0,0,0,0.48);
    -moz-box-shadow: 0px 0px 8px -3.5px rgba(0,0,0,0.48);
    -webkit-box-shadow: 0px 0px 8px -3.5px rgba(0,0,0,0.48);
    -o-box-shadow: 0px 0px 8px -3.5px rgba(0,0,0,0.48);
    display: flex;
    flex-direction: column;
}

.modal-context-body{
    overflow-x: auto;
    overflow-y: auto;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 2px;
    max-height:83%;
    height:83%;
    overflow:auto;
    box-sizing: border-box;
}
.modal-context-body::-webkit-scrollbar {
    display: none;
}
.modal-close-icon{
    position: absolute;
    top: 0px;
    right: 0px;
    padding-top: 15px;
    padding-right: 19px;
    cursor: pointer;
}

*:focus {
    outline: none;
}

@media only screen and (max-width: 600px) {
    .modal-cover {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 0px 0px 0px 0px;
    }

    .modal-close-icon{
        padding-right: 12px;
    }
}

@media screen and ( max-height: 600px ){
    .modal-cover {
        height: 100%;
        max-height: 100%;
    }
}

