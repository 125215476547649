.modal-header-container{
    padding-top: 16px;
    padding-left: 24px;
    background-color: #FFFFFF;
    border-radius: 8px 0px 0px 0px;
    padding-bottom: 24px;
}

.modal-header-tittle{
    color: #677784;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    height: auto;
    padding-bottom: 4px;
}

.modal-header-highlight{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #293036;
    height: auto;
    padding-bottom: 8px;
    max-width: 552px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.modal-header-secondary-value{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: #677684;
    height: 15.92px;
}

.modal-header-identifierValue-value{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    height: 15.92px;
    margin-bottom: 15px;
}

.modal-header-status-value{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: #293036;
    height: 15.92px;
    padding-top: 8px;
}

.modal-header-status-value .MuiChip-root{
    background: #E0FFF8;
    color: #3B9D86;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10.2857px;
    height: 18px;
}

.modal-header-close-icon{
    position: absolute;
    top: 0px;
    right: 0px;
    padding-top: 21px;
    padding-right: 19px;
    cursor: pointer;
}
